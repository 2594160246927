.documents-container {
  padding: 0;
  margin: 0;
  font-family: Arial;
  font-weight: 400;
  // width: 794px;
  width: 1100px;

  .mr-1 {
    margin-right: 10px;
  }
  .mr-2 {
    margin-right: 20px;
  }
  .mr-3 {
    margin-right: 30px;
  }
  .mt-1 {
    margin-top: 10px;
  }
  .mt-2 {
    margin-top: 20px;
  }
  .mt-3 {
    margin-top: 30px;
  }
  .mt-4 {
    margin-top: 40px;
  }
  .mt-5 {
    margin-top: 50px;
  }

  .pt-1 {
    padding-top: 10px;
  }
  .pt-2 {
    padding-top: 20px;
  }
  .pt-3 {
    padding-top: 30px;
  }

  .pl-1 {
    padding-left: 10px;
  }
  .pl-2 {
    padding-left: 20px;
  }
  .pl-3 {
    padding-left: 30px;
  }
  .row {
    display: flex;
    display: -webkit-flex;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
    align-items: flex-start;
    -webkit-align-items: flex-start;
  }
  .justify-content-center {
    justify-content: center;
    -webkit-justify-content: center;
  }
  .justify-content-between {
    justify-content: space-between;
    -webkit-justify-content: space-between;
  }
  table {
    width: 100%;
  }
  .table td,
  .table th {
    padding: 2px 4px;
    text-align: left;
    font-size: 12px;
  }
  table,
  th,
  td {
    border: 1px solid #000;
    border-collapse: collapse;
  }
  h4 {
    font-size: 12px !important;
    font-weight: 400;
    margin-top: 20px;
  }
  h3 {
    font-size: 12px !important;
    font-weight: 700;
  }
  .text-center {
    text-align: center !important;
  }
  .text-right {
    text-align: right !important;
  }
  .main {
    max-width: 1110px;
    margin: 0 auto;
  }
  /*--------------------*\
    sales confirmation
  \*--------------------*/
  .logo {
    /*margin-top: -32px;*/
  }

  .belgium-logo {
    position: absolute;
    top: 66px;
    left: 0px;
  }

  .belgium-logo img {
    max-width: 800px;
  }

  .logo img {
    max-width: 195px;
  }

  .header {
    margin-top: 51px;
  }
  .header .left {
    width: 57%;
  }
  .header .right {
    width: 43%;
    margin-top: 25px;
  }
  .header .left p {
    margin-top: 0;
    font-size: 10px;
  }
  .belgium-header p {
    margin-top: 0;
    font-size: 10px;
  }
  .address-box-header,
  .address-box-header-invoice {
    border: 1px solid #000;
    max-width: 416px;
    padding: 6px 5px 6px 15px;
  }
  .address-box-header ul,
  .address-box-header-invoice ul {
    padding: 0;
    margin: 0;
  }
  .address-box-header ul li,
  .address-box-header-invoice ul li {
    list-style: none;
    display: flex;
    display: -webkit-flex;
    font-size: 12px;
    line-height: 16px !important;
  }
  .address-box-header ul li a,
  .address-box-header-invoice ul li a {
    color: #000;
    text-decoration: none;
  }
  .address-box-header ul li:last-child,
  .address-box-header-invoice ul li:last-child {
    margin: 0;
  }

  .address-box-header ul li span:first-child {
    width: 15%;
  }

  .address-box-header-invoice ul li span:first-child {
    width: 28% !important;
  }

  .mt-12 {
    margin-top: 12px;
  }
  .mt-24 {
    margin-top: 24px !important;
  }
  .footer {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    align-items: flex-start;
    -webkit-align-items: flex-start;
  }
  .footer-widget {
    width: 33.33%;
  }
  .align-start{
    align-items: flex-start;

  }
  .footer-title_background{
    background: rgb(180,	34	,85);
  }
  .footer-title-color{
    color: #38484E;
  }
  .footer-title-color_1{
    color:rgb(180,	34	,85);
  }
  .footer-widget p {
    font-size: 10px;
    letter-spacing: 0.3px;
  }
  .footer_text{
   height: 16px;
  }
  .footer-widget:last-child p {
    padding-left: 100px;
  }

  .invoice-body h3 {
    /*margin-top: 4px;*/
    font-size: 14px !important;
    font-weight: 700;
  }
  .disc {
    margin-top: 40px;
    font-size: 12px;
  }
  .disc-2 {
    font-size: 12px;
  }
  .invoice-table {
    width: 100%;
  }
  .sales .table {
    margin-top: 55px;
    width: 100% !important;
  }
  .sales .table td,
  .sales .table th {
    padding: 4px 10px;
    text-align: left;
    font-size: 12px;
    padding-top: 0;
  }
  //.sales .date{
  //  margin-left: -22px;
  //}
  .sales .invoice-body h3 {
    margin-top: 25px;
  }
  .geschreddert {
    font-size: 12px;
    padding-left: 50px;
    margin-top: 20px;
  }
  .address {
    margin-top: 20px;
  }
  .reference-col {
    width: 15% !important;
  }
  .address p {
    font-size: 12px;
  }
  .sales .steuerschuldnerschaft {
    font-size: 12px;
    margin-top: 44px;
  }
  .sales .footer {
    /*padding: 152px 0 30px 0;*/
  }
  /*--------------------*\
   Purchase confirmation
  \*--------------------*/

  .purchase .invoice-body h3 {
    margin-top: 0;
  }
  .purchase .team-address {
    //margin-top: 77px;
  }
  .payment-terms,
  .payment-terms span {
    margin-top: 30px;
    font-size: 14px !important;
    font-weight: 700;
  }
  .purchase .footer {
    /*padding: 342px 0 30px 0;*/
  }
  .purchase .zahlungsziel {
    margin-top: 45px !important;
    font-size: 12px;
  }
  .purchase .lieferbedingung,
  .sales .lieferbedingung {
    font-size: 12px;
  }
  .purchase .geschreddert {
    padding-left: 37px;
  }
  .purchase .geschreddert span {
    margin-right: 25px;
  }
  table.table.mt-3.purchase-table {
    margin-top: 27px;
    width: 100%;
  }
  //.purchase .date {
  //  margin-left: -22px;
  //  margin-top: 50px;
  //}
  /*--------------------*\
      Invoice
  \*--------------------*/

  .total {
    max-width: 100%;
  }
  .total p {
    font-size: 12px;
    margin: 0;
    margin-top: 36px;
  }
  .total p + p {
    margin-top: 6px;
  }
  .total .price {
    min-width: 139px;
    display: inline-block;
  }
  .invoice .invoice-table {
    margin-top: 10px;
  }
  .invoice .invoice-table td {
    font-size: 12px;
  }
  .gesamtbetrag {
    margin-top: 14px !important;
  }

  .zahlungsziel {
    margin-top: 45px !important;
  }
  .mt-15 {
    margin-top: 15px;
  }
  .mg-22 {
    margin-top: 22px;
  }
  .date {
    font-size: 12px;
    font-weight: 400;
  }
  .team-address p {
    font-size: 12px;
  }
  .font-12 {
    font-size: 12px;
  }
  .underline {
    text-decoration: underline;
  }
  .white-space-no {
    white-space: nowrap;
  }

  tfoot {
    border: 1px solid #ffffff !important;
  }

  tfoot td,
  tfoot th {
    border: none !important;
  }

  .tbl {
    display: flex;
    flex-direction: column;
  }

  .tbl .row {
    display: flex;
    min-height: 15px;
    font-size: 12px;
    page-break-inside: avoid;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    align-items: unset;
    -webkit-align-items: unset;
  }

  .cell {
    width: 14%;
    font-size: 12px;
    border-right: 1px solid black;
  }

  .tbl .row {
    margin-left: -1px;
    margin-top: -1px;
  }

  .row .cell {
    padding: 2px;
    box-sizing: border-box;
  }

  .bold {
    font-weight: bold;
  }

  .border-none {
    border: none !important;
  }

  .first-col {
    width: 32.8% !important;
  }

  .cell-small {
    width: 18.9% !important;
  }

  .invoice-first-col {
    width: 32.8% !important;
  }

  .show-only-on-pdf {
    display: none;
  }
}
