.contract_doc * {
    font-family:Arial, Helvetica, sans-serif;
    box-sizing: border-box;
}
/*h1 , h2 , h3 , h4 , h5 , h6 { font-weight: 600;}*/
.contract_doc .row{display: flex;}
.contract_doc .row-margin{margin-bottom: 1.5rem;}
.contract_doc .col-margin{margin-bottom: 1rem;}
.contract_doc .padding-2{padding-bottom: .8rem;}
.contract_doc .padding-3{padding-bottom: 1rem;}
.contract_doc .padding-4{padding-bottom: 1.5rem;}
.contract_doc .margin-2{margin-bottom: .5rem;}
.contract_doc .p-margin{margin: 0;}
.contract_doc .col-align{text-align: center;}
.contract_doc .bottom-border{border-bottom: 2px solid #000;}
.contract_doc .column-1{
    float: left;
    width: 8.333333%;
}
.contract_doc .column-2{
    float: left;
    width: 16.666667%;
}
.contract_doc.column-3{
    float: left;
    width: 25%;
}
.contract_doc .column-4{
    float: left;
    width: 33.333333%;
}
.contract_doc .column-6{
    float: left;
    width: 50%;
}
.contract_doc .column-7{
    float: left;
    width: 58.333333%;
}
.contract_doc .column-8{
    float: left;
    width: 66.666667%;
}
.contract_doc .column-9{
    float: left;
    width: 75%;
}
.contract_doc .column-12{
    float: left;
    width: 100%;
}
.contract_doc .column-10{
    float: left;
    width: 83.333333%;;
}
.contract_doc .row:after {
    content: "";
    display: table;
    clear: both;
}
.contract_doc .column-12 h5{font-weight: bold;}
.contract_doc .column-6 h5{font-size: 20px;}
.contract_doc.column-6 p{font-size: 14px;}
.contract_doc .header2 h4{font-weight: bold;}
.contract_doc .header h4{font-weight: bold; margin: 0}
.contract_doc .bottom-border2{border-bottom: 2px solid #000; width: 65%;}
.contract_doc .bottom-border3{border-bottom: 2px solid #000; width: 18%;}
.contract_doc .bottom-border4{border-bottom: 2px solid #000; width: 40%;}
.contract_doc .bottom-border5{border-bottom: 2px solid #000; width: 42%;}
.contract_doc .bottom-border6{border-bottom: 2px solid #000; width: 65%;}
.contract_doc.header-h5 h5 ,.contract_doc .header-h4 h4{font-weight: bold;}
.contract_doc.header-h4 h4{margin: 0;}
.contract_doc.header3-h5 h5{font-weight: 500;}
.contract_doc h5 {
    font-size: 18px;
}

.contract_doc .pod-data {
    margin-left: 15%;
    margin-top: 10%;
}

.flex-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    display:  -webkit-flex;
    -webkit-flex-direction: column;
    -webkit-justify-content: flex-end;
}
.contract-margin-0{
    margin: 0 !important;
}
.margin-top-22{
    margin-top: 22px !important;
}
.contract-header-border{
    display: inline-block;
    border-bottom: 1px solid #000;
}
.show-only-on-pdf {
    display: none;
}
.width-195{
width: 195px;
}